export default {
  navigation: {
    '.logo': {
      img: {
        height: '75px'
      }
    },
    '.logoScrolled': {
      img: {
        height: '65px'
      }
    }
  },

  footer: {
    backgroundColor: 'black',
    '.copyright': {
      fontSize: '0.8rem'
    },
    '.footerprint': {
      alignItems: ['center', '', '', 'flex-end']
    },
    '.multiButtonContainer': {
      margin: '1rem 0rem',
      a: {
        variant: 'buttons.primary'
      }
    }
  },

  contactForm: {
    backgroundColor: 'secondary',
    color: 'white',
    '.inputField, .textField ': {
      borderColor: 'primary',
      borderRadius: '0px',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    }
  },

  heroV7: {
    '.title': {
      fontSize: ['2.5rem', '', '', '3.5rem']
    },
    '.hero_content_container': {
      marginBottom: '5rem'
    }
  },

  sideBySideV1: {
    padding: '3rem 0rem',
    '.content': {
      order: '2'
    },
    '.title': {
      borderBottom: '3px solid',
      borderColor: 'light',
      fontWeight: '400',
      width: 'fit-content'
    },
    '.subtitle': {
      borderLeft: '6px double',
      borderColor: 'primary',
      paddingLeft: '0.5rem'
    },

    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySideV2b: {
    padding: ['4rem 1rem', '', '', '6rem 1rem'],
    '.content': {
      alignItems: 'flex-start',
      textAlign: 'left'
    },
    img: {},
    '.title': {
      borderBottom: '3px solid',
      borderColor: 'light',
      fontWeight: '400'
    },
    '.subtitle': {
      borderLeft: '6px double',
      borderColor: 'primary',
      paddingLeft: '1rem'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySideV5: {
    padding: '3rem 0rem',
    '.title': {
      borderBottom: '3px solid',
      borderColor: 'light',
      fontWeight: '400',
      width: 'fit-content'
    },
    '.subtitle': {
      borderLeft: '6px double',
      borderColor: 'primary',
      paddingLeft: '0.5rem'
    },

    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  shoutSectionV2: {
    padding: ['1rem', , '0rem'],
    '.title, .date, .text': {
      textAlign: ['left', 'left', 'left', 'left']
    },
    '.title': {
      fontSize: ['1.5rem', '1.75rem'],
      color: 'primary',
      fontWeight: '400',
      textDecoration: 'underline double'
    },
    '.text': {
      fontSize: ['1.5rem', '', '2rem', '2rem'],
      fontFamily: 'heading',
      fontWeight: 'heading'
    },
    '.date': {
      opacity: '0.7',
      fontSize: '1rem'
    },
    '.imageContainer': {
      order: '2'
    },
    '.content': {
      order: '1',
      padding: ['1rem 0rem', '0.5rem', '1rem', '2rem']
    }
  },

  contentSectionV1: {
    color: 'white',
    borderTop: 'solid 3px',
    borderBottom: 'solid 3px',
    borderColor: 'primary',
    padding: '8rem 1rem',
    '.title': {
      textAlign: 'center'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      py: '1rem',
      px: '2rem'
    }
  },

  heroV2b: {
    height: '90vh',
    overflow: 'hidden',
    '.title': {
      letterSpacing: '3px',
      fontSize: ['1.75rem', '', '2rem', '2.5rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    },
    '.slick-slide img': {
      height: '100%',
      filter: 'brightness(0.8)'
    },
    '.hero_content_container': {
      color: 'white',
      width: '100%',
      maxWidth: '767px',
      margin: ['0px', '0px', '0px', '0px'],
      marginLeft: ['0px', '0px', '0px', '0px']
    },
    '.slick-initialized .slick-slide > div': {
      height: '90vh'
    },
    '.slick-arrow': {
      zIndex: '100',
      color: 'primary',
      width: ['25px', '', '50px'],
      height: ['25px', '', '50px'],
      ':hover': {
        color: 'primary',
        opacity: '0.5'
      }
    },
    '.slick-prev': {
      left: '0px'
    },
    '.slick-next': {
      right: '0px'
    },
    '.slick-dots li button:before': {
      fontSize: '20px',
      color: 'primary'
    },
    '.slick-dots li.slick-active button:before': {
      color: 'primary'
    }
  }
}
